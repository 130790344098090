<script setup>
// @ is an alias to /src
import FooterBar from "@/components/FooterBar.vue";
import NavigationBar from "@/components/NavigationBar.vue";
import {
  BuildingOffice2Icon,
  EnvelopeIcon,
  MapIcon,
  MapPinIcon,
} from "@heroicons/vue/24/outline";
import {
  LCircle,
  LIcon,
  LMap,
  LMarker,
  LTileLayer,
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";

const zoom = 16;
var iconeEntrada =
  "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png";
var sizeIcone = [25, 35];

// URL da imagem do ícone personalizado

const markerCoords = [-3.6780585071583674, -40.356860558802694];

// const form = {
//   nome: "",
//   email: "",
//   mensagem: "",
// };

const enviar = () => {
  alert(
    "Nome: " +
      form.nome +
      " " +
      form.sobrenome +
      "\nE-mail: " +
      form.email +
      "\nMensagem: " +
      form.mensagem
  );
};
</script>

<template>
  <NavigationBar msg="" />

  <body class="md:mt-16">
    <!-- <h1 class="text-4xl font-bold text-gray-800">Contato</h1> -->

    <div class="grid sm:grid-cols-2">
      <div class="col-span-1 px-8 pt-24 bg-igep-blue">
        <div class="text-start sm:pl-16">
          <h2
            class="mb-4 text-2xl font-bold tracking-tight text-white sm:mb-8 sm:text-4xl"
          >
            Entre em contato
          </h2>
          <div>
            <!-- <div class="flex py-2">
              <p class="flex text-white">
                <PhoneIcon class="w-5 h-5 text-white" />
                <span class="ml-1 mr-1 font-semibold">Telefone:</span>
                <span>(11) 99999-9999</span>
              </p>
            </div> -->
            <div class="flex py-2">
              <p class="flex text-white">
                <EnvelopeIcon class="w-5 h-5 text-white" />
                <span class="ml-1 mr-1 font-semibold">Email:</span>
                <span>atendimento@igepsaude.org.br</span>
              </p>
            </div>
          </div>
        </div>
        <div class="mt-6 sm:mt-16 text-start sm:pl-16">
          <h2
            class="mb-4 text-2xl font-bold tracking-tight text-white sm:mb-8 sm:text-4xl"
          >
            Localização
          </h2>
          <div>
            <div class="flex py-2">
              <p class="flex text-white">
                <BuildingOffice2Icon class="w-5 h-5 text-white" />
                <span class="ml-1 mr-1 font-semibold">Endereco:</span>
                <span>Rua Dom Lourenço, 284 - Campo dos Velhos</span>
              </p>
            </div>
            <div class="flex py-2">
              <p class="flex text-white">
                <MapIcon class="w-5 h-5 text-white" />
                <span class="ml-1 mr-1 font-semibold">Cidade - UF:</span>
                <span>Sobral - Ceará </span>
              </p>
            </div>
            <div class="flex py-2">
              <p class="flex text-white">
                <MapPinIcon class="w-5 h-5 text-white" />
                <span class="ml-1 mr-1 font-semibold">CEP:</span>
                <span>62.030-190 </span>
              </p>
            </div>
          </div>
          <div class="pb-20 mt-6 mr-4 sm:mr-16">
            <l-map
              ref="map"
              v-model:zoom="zoom"
              :center="markerCoords"
              style="height: 300px"
            >
              <l-tile-layer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                layer-type="base"
                name="OpenStreetMap"
              >
                <l-circle :radius="markerRadius"></l-circle>
              </l-tile-layer>
              <l-marker :lat-lng="markerCoords">
                <l-icon
                  class="w-4 h-4"
                  :icon-url="iconeEntrada"
                  :icon-size="sizeIcone"
                >
                </l-icon>
              </l-marker>
            </l-map>
          </div>
        </div>
      </div>
      <div class="col-span-1 mt-16 sm:px-12">
        <form
          class="m-auto mb-10 rounded-lg sm:mt-16 sm:px-5 bg-opacity-80 md:w-full py-7"
          action="https://formsubmit.co/atendimento@igepsaude.org.br"
          method="POST"
        >
          <div class="flex flex-wrap px-10 mb-6">
            <div class="w-full px-3 mb-6 md:mb-0">
              <label
                class="block mb-2 text-xs font-bold tracking-wide uppercase text-igep-dark-blue"
                for="grid-first-name"
              >
                Nome
              </label>
              <input
                v-model="nome"
                class="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-first-name"
                type="text"
                placeholder="Nome"
                name="name"
              />
              <!-- <p class="text-xs italic text-red-500">Please fill out this field.</p> -->
            </div>
          </div>
          <div class="flex flex-wrap px-10 mb-6">
            <div class="w-full px-3">
              <label
                class="block mb-2 text-xs font-bold tracking-wide uppercase text-igep-dark-blue"
                for="grid-password"
              >
                E-mail
              </label>
              <input
                v-model="email"
                class="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-password"
                type="email"
                placeholder="E-mail"
                name="email"
              />
              <!-- <p class="text-xs italic text-gray-600">Make it as long and as crazy as you'd like</p> -->
            </div>
          </div>
          <div class="flex flex-wrap px-10 mb-6">
            <div class="w-full px-3">
              <label
                class="block mb-2 text-xs font-bold tracking-wide uppercase text-igep-dark-blue"
                for="grid-password"
              >
                Mensagem
              </label>
              <textarea
                v-model="mensagem"
                class="block w-full h-48 px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none resize-none no-resize focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-password"
                type="password"
                placeholder="Mensagem"
                name="mensagem"
              ></textarea>
              <!-- <p class="text-xs italic text-gray-600">Re-purpose the cardigan by removing buttons and sewing</p> -->
            </div>
          </div>
          <div class="flex justify-end pr-4 sm:pr-12">
            <div class="text-end">
              <button
                class="px-4 py-2 font-bold text-white bg-blue-500 rounded shadow hover:bg-blue-400 focus:shadow-outline focus:outline-none"
                type="submit"
              >
                Enviar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </body>

  <FooterBar />
</template>
